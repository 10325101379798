import request from '@/api/request'

export function fetchList(params) {
    return request({
        url: '/model-analysis/asset/inventory/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/asset/inventory/add',
        method: 'post',
        data,
    })
}

export function fetchDetail({ id }) {
    return request({
        url: '/model-analysis/asset/inventory/query/' + id,
    })
}


// 取消申请
export function cancel(data) {
    return request({
        url: '/model-analysis/asset/inventory/cancel',
        method: 'post',
        data,
    })
}

// 删除
export function remove(data) {
    return request({
        url: '/model-analysis/asset/inventory/delete',
        method: 'post',
        data,
    })
}

// 修改盘点单
export function edit(data) {
    return request({
        url: '/model-analysis/asset/inventory/update',
        method: 'post',
        data,
    })
}

// 盘点
export function check(data) {
    return request({
        url: '/model-analysis/asset/inventory/check',
        method: 'post',
        data,
    })
}