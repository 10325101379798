<template>
  <div>
    <div class="right" style="padding-bottom: 2vh" v-if="type === 'update'">
      <a-button type="primary" @click="add">添加</a-button>
    </div>

    <a-table
      bordered
      size="small"
      :dataSource="selected"
      rowKey="id"
      :pagination="false"
    >
      <a-table-column key="code" title="资产编号" data-index="code" />
      <a-table-column key="name" title="资产名称" data-index="name" />
      <a-table-column key="brand" title="品牌" data-index="brand" />
      <a-table-column
        key="specification"
        title="规格型号"
        data-index="specification"
      />
      <a-table-column key="unit" title="单位" data-index="unit" />

      <a-table-column
        key="status"
        title="盘点状态"
        align="center"
        v-if="type !== 'update'"
      >
        <template slot-scope="text">
          <a-select v-model="text.status" size="small">
            <a-select-option
              v-for="item in statusList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>

      <a-table-column
        key="control"
        title="操作"
        align="center"
        v-if="type === 'update'"
      >
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      title="选择资产"
      :visible="visible"
      @cancel="cancel"
      @ok="ok"
      width="800px"
    >
      <a-table
        bordered
        size="small"
        :dataSource="list"
        @change="onChange"
        rowKey="id"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="code" title="资产编号" data-index="code" />
        <a-table-column key="name" title="资产名称" data-index="name" />
        <a-table-column key="brand" title="品牌" data-index="brand" />
        <a-table-column
          key="specification"
          title="规格型号"
          data-index="specification"
        />
        <a-table-column key="unit" title="单位" data-index="unit" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchList } from "../../api/list";
import { mapGetters } from "vuex";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "update",
    },
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],

      visible: false,
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("AssetInventoryMaterialStatus");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = Object.freeze(res.list);
          this.total = res.totalSize;
        }
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSelectChange(keys) {
      console.log("keys", keys);
      this.selectedRowKeys = keys;
      keys.forEach((key) => {
        const item = this.list.find((item) => item.id === key);
        if (item) {
          this.selectedRows.push(item);
        }
      });
      this.selectedRows = [...new Set(this.selectedRows)];
    },

    add() {
      this.visible = true;
    },

    remove(index) {
      this.selected.splice(index, 1);
    },

    cancel() {
      this.visible = false;
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    ok() {
      this.selectedRows.forEach((item) => {
        const element = this.selected.find((element) => element.id === item.id);
        if (!element) {
          this.selected.push(item);
        }
      });
      this.cancel();
    },
  },
};
</script>